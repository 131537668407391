import React, { useState, useEffect } from "react";
import Loader from "../../baseComponents/Loader";
import NotFound from "../../baseComponents/NotFound";
import { useNavigate, useParams } from "react-router-dom";

import { getThematic } from "../../services/interviews";

import doubleGear from "../../assets/images/gear_double.svg";
import doubleBig from "../../assets/images/gear_big.svg";
import doubleSmall from "../../assets/images/gear_small.svg";

import "./index.css";
import { HeaderBack } from "../../baseComponents/BackButton";

const ThematicInterview = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [info, setInfo] = useState(null);
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [isHidden, setHidden] = useState(false);

  useEffect(() => {
    getThematic(id)
      .then((res) => setInfo(res))
      .finally(() => setIsFetching(false));
  }, [id]);

  const handleBack = () => {
    setHidden(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  if (isFetching) return <Loader />;
  if (!Boolean(info)) return <NotFound />;

  let video =
    info.metas instanceof Array
      ? info.metas.find((meta) => meta.extra_thumbnail)
      : {};
  video = video.extra_video ? video.extra_video : "";

  return (
    <div className={`thematic_interview ${isHidden ? "hidden" : ""}`}>
      <div className="decorative-small-wrap">
        <img
          src={doubleSmall}
          alt="engrane pequeño"
          className="thematic_interview__content_decorative small"
        />
      </div>
      <div className="decorative-big-wrap">
        <img
          src={doubleBig}
          alt="engrane grande"
          className="thematic_interview__content_decorative big"
        />
      </div>
      <div className="thematic_interview__header">
        <div className="thematic_interview__header_content">
          <HeaderBack
            onClick={handleBack}
            onNavigateHome={() => setHidden(true)}
          />
          <h1>
            <div className="mask">
              <div className="text">{info.title}</div>
            </div>
          </h1>
        </div>
      </div>
      <div className="thematic_interview__content">
        <img
          src={doubleGear}
          alt="engrane doble"
          className="thematic_interview__content_decorative double"
        />
        <div className="thematic_interview__content_video">
          <video
            controls
            disablePictureInPicture
            controlslist="nodownload noplaybackrate"
            autoPlay
          >
            <source src={video} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};

export default ThematicInterview;
