const routes = {
  home: {
    url: "/",
  },
  timeline: {
    url: "/linea-de-tiempo",
  },
  interviews: {
    url: "/entrevistas",
  },
  interview_list: {
    url: "/entrevistas/individuales",
  },
  thematic_list: {
    url: "/entrevistas/tematicas",
  },
};

export const getUrl = (routeName) => {
  let route = routes[routeName];
  return route ? route.url : "/";
};

export const getUrlWithoutSlash = (routeName) => {
  let route = routes[routeName];
  return route ? route.url.replace("/", "") : "";
};
