import React, { useEffect, useState } from "react";
import engine from "../../../assets/images/engine.svg";
import mask from "../../../assets/images/mask.jpg";

const Item = ({
  item = null,
  index = Math.random(),
  isLast = false,
  onClick = () => null,
}) => {
  const [activeClass, setActiveClass] = useState("");
  const [activeArrowClass, setActiveArrowClass] = useState("");
  useEffect(() => {
    const activeTimeout = setTimeout(() => {
      setActiveClass("active");
    }, 80);
    const arrowTimeout = setTimeout(() => {
      setActiveArrowClass("active");
    }, index * 150);
    return () => {
      clearTimeout(activeTimeout);
      clearTimeout(arrowTimeout);
    };
  }, [index]);

  if (!item) return null;

  let thumbnail =
    item.metas instanceof Array
      ? item.metas.find((meta) => meta.extra_thumbnail)
      : {};
  thumbnail = thumbnail.extra_thumbnail ? thumbnail.extra_thumbnail : mask;

  return (
    <div className={`single_interview__item ${activeClass}`}>
      <div className="single_interview__item_avatar" onClick={onClick}>
        <img src={thumbnail} alt="Foto de perfil" />
        {(index + 1) % 4 !== 0 && !isLast && (
          <div
            className={`single_interview__item_shape ${activeArrowClass} ${
              index % 2 === 0 ? "top" : "bottom"
            }`}
          >
            <div className="single_interview__item_engine">
              <img src={engine} alt="engrane" />
            </div>
          </div>
        )}
      </div>
      <div className="single_interview__item_text" onClick={onClick}>
        <p className="single_interview__item_title">{item.title}</p>
      </div>
    </div>
  );
};

export default Item;
