import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { HeaderBack } from "../../baseComponents/BackButton";
import Item from "./components/Item";
import Loader from "../../baseComponents/Loader";

import { useNavigate } from "react-router-dom";
import { getThematicInterviewsList } from "../../services/interviews";
import { getUrl } from "../../utils/routes";

import connector from "../../assets/images/connector_horizontal.svg";
import { ReactComponent as ArrowPrev } from "../../assets/images/arrow_btn_prev.svg";
import { ReactComponent as ArrowNext } from "../../assets/images/arrow_btn_next.svg";

import "./index.css";
import useWindowSize from "../../utils/useWindowSize";

const SlideView = ({ items, handleBtn = () => null }) => {
  return (
    <div className="thematic_interviews__slide">
      {items.map((item, index) => {
        const onClick = () => handleBtn(item);
        return (
          <Item
            key={index}
            onClick={onClick}
            item={item}
            index={index}
            limit={items.length - 1}
          />
        );
      })}
    </div>
  );
};

const ThematicInterviewList = (props) => {
  const [isClickEnable, setIsClickEnable] = useState(true);
  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [isHidden, setHidden] = useState(false);
  const windowSize = useWindowSize();
  const [currentList, setCurrentList] = useState();

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const onPrevSlide = () => {
    if (containerRef.current) containerRef.current.slickPrev();
  };
  const onNextSlide = () => {
    if (containerRef.current) containerRef.current.slickNext();
  };

  const handleBtn = (item) => {
    if (!isClickEnable) return;
    setIsClickEnable(!isClickEnable);
    setHidden(true);
    setTimeout(() => {
      navigate(`${getUrl("thematic_list")}/${item.id}`);
    }, 1500);
  };

  const handleBack = () => {
    setHidden(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  const handleList = () => {
    if (windowSize.width < 992) {
      let newList = [...[].concat.apply([], list)];
      const newArr = [];
      while (newList.length) newArr.push(newList.splice(0, 1));
      setCurrentList(newArr);
    } else {
      setCurrentList(list);
    }
  };

  useEffect(() => {
    getThematicInterviewsList()
      .then((list) => {
        setList(list);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  useEffect(() => {
    handleList();
    // eslint-disable-next-line
  }, [list, windowSize]);

  if (isFetching) return <Loader />;

  return (
    <div className={`thematic_interviews ${isHidden ? "hidden" : ""}`}>
      <div className="thematic_interviews__back">
        <HeaderBack
          onClick={handleBack}
          onNavigateHome={() => setHidden(true)}
        />
      </div>
      <div className="thematic_interviews__header">
        <h1>
          <div className="mask">
            <div className="text">Temáticas</div>
          </div>
        </h1>
      </div>
      <div className="thematic_interviews__content">
        <ArrowPrev
          onClick={onPrevSlide}
          className="thematic_interviews__btn left"
        />
        <Slider
          {...settings}
          className="thematic_interviews__items"
          ref={containerRef}
        >
          {currentList.map((item, index) => {
            return <SlideView key={index} items={item} handleBtn={handleBtn} />;
          })}
        </Slider>
        <ArrowNext
          onClick={onNextSlide}
          className="thematic_interviews__btn right"
        />
      </div>
      <div className="shape-container">
        <img
          src={connector}
          alt="conector"
          className="thematic_interviews__shape right"
        />
      </div>
    </div>
  );
};

export default ThematicInterviewList;
