import React, { useState, useEffect } from "react";
import NotFound from "../../baseComponents/NotFound";
import Loader from "../../baseComponents/Loader";

import { getInterview } from "../../services/interviews";
import { useNavigate, useParams } from "react-router-dom";

import doubleGear from "../../assets/images/gear_double.svg";
import doubleBig from "../../assets/images/gear_big.svg";
import doubleSmall from "../../assets/images/gear_small.svg";

import "./index.css";
import { HeaderBack } from "../../baseComponents/BackButton";

const Interview = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [info, setInfo] = useState(null);
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [isHidden, setHidden] = useState(false);

  useEffect(() => {
    getInterview(id)
      .then((res) => setInfo(res))
      .finally(() => setIsFetching(false));
  }, [id]);

  const handleBack = () => {
    setHidden(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  if (isFetching) return <Loader />;
  if (!Boolean(info)) return <NotFound />;

  let video =
    info.metas instanceof Array
      ? info.metas.find((meta) => meta.extra_thumbnail)
      : {};
  video = video.extra_video ? video.extra_video : "";

  return (
    <div className={`interview ${isHidden ? "hidden" : ""}`}>
      <div className="decorative-small-wrap">
        <img
          src={doubleSmall}
          alt="engrane pequeño"
          className="interview__content_decorative small"
        />
      </div>
      <div className="decorative-big-wrap">
        <img
          src={doubleBig}
          alt="engrane grande"
          className="interview__content_decorative big"
        />
      </div>
      <div className="interview__header">
        <div className="interview__header_content">
          <HeaderBack
            onClick={handleBack}
            onNavigateHome={() => setHidden(true)}
          />
          <h1>
            <div className="mask">
              <div className="text">{info.title}</div>
            </div>
          </h1>
        </div>
      </div>
      <div className="interview__content">
        <div className="interview__content_media">
          <img
            src={doubleGear}
            alt="engrane doble"
            className="interview__content_decorative double"
          />
          <div className="interview__content_video">
            <video
              controls
              disablePictureInPicture
              controlslist="nodownload noplaybackrate"
              autoPlay
            >
              {typeof video === "string" && (
                <source src={video} type="video/mp4" />
              )}
            </video>
          </div>
        </div>
        <div className="interview__content_info">
          <h2 className="upper">Semblanza</h2>
          <div
            className="interview__content_abstract"
            dangerouslySetInnerHTML={{ __html: info.content }}
          />
          <h2 className="upper">Premio</h2>
          {info.metas.map((meta) => (
            <div className="place_block">
              <p className="place title">
                <b>Título de la investigación:</b> {meta.extra_title}
              </p>
              <p className="place">
                <b>Lugar:</b> {meta.extra_place}
              </p>
              <p className="place">
                <b>Categoría:</b> {meta.extra_category}
              </p>
              <p className="place">
                <b>Año:</b> {meta.extra_year}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Interview;
