import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import back from "../assets/images/arrow_back.svg";
import home from "../assets/images/home_btn.svg";

const BackButton = ({ onClick = () => null }) => (
  <img onClick={onClick} src={back} className="back_button" alt="Atrás" />
);

export const HomeButton = () => {
  const navigate = useNavigate();
  return (
    <img
      onClick={() => navigate("/")}
      src={home}
      className="back_button"
      alt="Atrás"
    />
  );
};

export const HeaderBack = ({
  onClick = () => null,
  onNavigateHome = () => null,
}) => {
  const [isClickEnable, setIsClickEnable] = useState(true);
  const navigate = useNavigate();
  const navigateHome = () => {
    onNavigateHome();
    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  return (
    <div className="header__back">
      <div className="back_button_wrap">
        {isClickEnable && (
          <img
            onClick={() => {
              setIsClickEnable(!isClickEnable);
              onClick();
            }}
            src={back}
            className="back_button"
            alt="Atrás"
          />
        )}
      </div>
      <div className="home_btn_wrap">
        <img
          onClick={navigateHome}
          src={home}
          className="back_button home_btn"
          alt="Home"
        />
      </div>
    </div>
  );
};

export default BackButton;
