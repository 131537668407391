import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUrl } from "../../utils/routes";
import Lottie from "lottie-react";
import doubleBig from "../../assets/images/gear_big.svg";
import connector_diagonal from "../../assets/images/connector_diagonal.svg";
import clock from "../../assets/images/clock.json";
import camera from "../../assets/images/camera.json";

import "./index.css";

const Home = (props) => {
  const navigate = useNavigate();
  const [isHidden, setHidden] = useState(false);

  const handleBtn = (i) => {
    switch (i) {
      case 0:
        setHidden(true);
        setTimeout(() => {
          navigate(getUrl("interviews"));
        }, 1000);
        break;
      case 1:
        setHidden(true);
        setTimeout(() => {
          navigate(getUrl("timeline"));
        }, 1000);
        break;

      default:
        break;
    }
  };

  const customProps = {};

  if (process.env.REACT_APP_DESKTOP_MODE) {
    customProps["loop"] = false;
  }

  return (
    <div className={`home ${isHidden ? "hidden" : ""}`}>
      <div className="home__header">
        <div className="connector_wrap">
          <img
            className="connector_diagonal"
            src={connector_diagonal}
            alt="conector"
          />
        </div>
        <h1>
          <div className="mask">
            <div className="text">
              <b>Premio</b>
            </div>
          </div>
          <div className="mask">
            <div className="text">citibanamex</div>
          </div>
          <div className="mask">
            <div className="text">
              <b>de economía</b>
            </div>
          </div>
        </h1>
      </div>
      <div className="home__subtitle">
        <div className="mask">
          <h3>Un premio actual, un premio con historia y con futuro</h3>
        </div>
      </div>
      <div className="home__paragraphs">
        <div className="wrap">
          <div className="doubleBig-wrap">
            <img src={doubleBig} alt="rueda" />
          </div>
          <div className="paragraph">
            <p>
              A través de la lista completa de ganadores y jurado, entrevistas a
              una selección de ganadores, numeralia histórica y la lista de los
              temas que ha reconocido el Premio, este sitio te acerca de forma
              atractiva a las motivaciones, ideas transformadoras y propuestas a
              problemas sociales que impulsaron a distintas generaciones a
              utilizar la investigación en economía como una herramienta para
              cambiar favorablemente nuestro entorno. <br /> <br /> ¡Entra y
              descúbrelo!
            </p>
          </div>
        </div>
      </div>
      <div className="home__button_container left">
        <div
          className={`home__button`}
          onClick={(event) => {
            if (event.currentTarget.disabled) return;
            event.currentTarget.disabled = true;
            handleBtn(0);
          }}
        >
          <div className="home__button_mask">
            <div className="home__button_icon">
              <Lottie animationData={camera} {...customProps} />
            </div>
          </div>
        </div>
        <div className="home__button_container_text">
          <div className="home__button_text">
            <p>Entrevistas a ganadores</p>
          </div>
        </div>
      </div>
      <div className="home__button_container right">
        <div
          className={`home__button`}
          onClick={(event) => {
            if (event.currentTarget.disabled) return;
            event.currentTarget.disabled = true;
            handleBtn(1);
          }}
        >
          <div className="home__button_mask">
            <div className="home__button_icon">
              <Lottie animationData={clock} {...customProps} />
            </div>
          </div>
        </div>
        <div className="home__button_container_text">
          <div className="home__button_text">
            <p>Datos</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
