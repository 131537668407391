import { Routes, Route } from "react-router-dom";
import { getUrl } from "./utils/routes";

import Home from "./pages/Home";
import Interviews from "./pages/Interviews";
import Timeline from "./pages/GraphsView";
import GraphView from "./pages/GraphView";
import SingleInterviewList from "./pages/SingleInterviewList";
import ThematicInterviewList from "./pages/ThematicInterviewList";
import Interview from "./pages/Interview";
import ThematicInterview from "./pages/ThematicInterview";
import NotFound from "./baseComponents/NotFound";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Routes>
      <Route path={getUrl("home")} element={<Home />} />
      <Route path={getUrl("interviews")} element={<Interviews />} />
      <Route path={getUrl("timeline")} element={<Timeline />} />
      <Route path={`${getUrl("timeline")}/:id`} element={<GraphView />} />
      <Route
        path={getUrl("interview_list")}
        element={<SingleInterviewList />}
      />
      <Route
        path={getUrl("thematic_list")}
        element={<ThematicInterviewList />}
      />
      <Route path={`${getUrl("interview_list")}/:id`} element={<Interview />} />
      <Route
        path={`${getUrl("thematic_list")}/:id`}
        element={<ThematicInterview />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
