import React, { memo, useEffect, useRef, useState } from 'react';

import { ReactComponent as GreenCurveIcon } from '../../../assets/images/curve_green.svg';
import { ReactComponent as GreyCurveIcon } from '../../../assets/images/curve_grey.svg';

const INITIAL_DECADE = 1950;

const GrayCurve = memo(
  ({
    decade = 0,
    current = 0,
    width = 1,
    index = 0,
    decadeIndex = 0,
    amoutOfDecades = 1,
  }) => {
    const [padding, setPadding] = useState('none');
    // eslint-disable-next-line
    const [isDouble, setIsDouble] = useState(Math.random() > 0.5);
    const elementRef = useRef(null);

    useEffect(() => {
      if (elementRef.current) {
        if (decadeIndex === 0 && index === 0) return setPadding('left');
        if (decadeIndex === amoutOfDecades - 1 && index === amoutOfDecades - 1)
          return setPadding('right');
        if (decade === current) {
          const greyCurve = elementRef.current.getBoundingClientRect();
          const greenCurve = document
            .querySelector('.timeline__selector_item.green.active')
            .getBoundingClientRect();
          const distanceGreen = greenCurve.x - greenCurve.width / 2;
          const distanceGray = greyCurve.x - greenCurve.width / 2;
          const distance = distanceGreen - distanceGray;
          return setPadding(distance < 0 ? 'left' : 'right');
        }
        setPadding('none');
      }
    }, [index, decadeIndex, amoutOfDecades, current, decade]);

    if (isDouble)
      return (
        <React.Fragment>
          <div
            className="timeline__selector_item grey"
            style={{
              height: '10px',
              width: `${padding === 'left' ? width / 1.3 : 0}% `,
            }}
          />
          <div
            ref={elementRef}
            className="timeline__selector_item grey double"
            style={{ width: `${width}% ` }}
          >
            <GreyCurveIcon />
            <GreyCurveIcon className="second" />
          </div>
          <div
            className="timeline__selector_item grey"
            style={{
              height: '10px',
              width: `${padding === 'right' ? width / 1.3 : 0}% `,
            }}
          />
        </React.Fragment>
      );
    return (
      <React.Fragment>
        <div
          className="timeline__selector_item grey"
          style={{
            height: '10px',
            width: `${padding === 'left' ? width / 1.3 : 0}% `,
          }}
        />
        <div
          ref={elementRef}
          className="timeline__selector_item grey"
          style={{ width: `${width}% ` }}
        >
          <GreyCurveIcon />
        </div>
        <div
          className="timeline__selector_item grey"
          style={{
            height: '10px',
            width: `${padding === 'right' ? width / 1.3 : 0}% `,
          }}
        />
      </React.Fragment>
    );
  }
);

const GrayLayer = ({
  decades = [],
  prev = INITIAL_DECADE,
  current = INITIAL_DECADE,
  decadeIndex = 0,
  width = 1,
  amoutOfDecades = 1,
}) => {
  return (
    <div className="timeline__selector_grey_layer">
      <div
        className="timeline__selector_item grey"
        style={{ height: '10px', width: `${width / 2}% ` }}
      />
      {decades.map((decade, index) => (
        <div>
          <GreyCurveIcon />
        </div>
      ))}
      <div
        className="timeline__selector_item grey"
        style={{ height: '10px', width: `${width / 2}% ` }}
      />
    </div>
  );
};

const Selector = ({
  decades = [],
  decadeIndex = 0,
  current = INITIAL_DECADE,
  prev = INITIAL_DECADE,
  onChange = () => null,
}) => {
  useEffect(() => {
    if (window.innerWidth < 1200) {
      document.querySelectorAll('.timeline__selector > *').forEach((e) => {
        e.style.transform = `translateX(${
          -8.5 * decades.indexOf(current.toString())
        }rem)`;
      });
    } else {
      document.querySelectorAll('.timeline__selector > *').forEach((e) => {
        e.style.transform = '';
      });
    }
  }, [current]);

  const width = 100 / (decades.length + 3);
  return (
    <div className="timeline__selector_container">
      <div className="timeline__selector grey">
        {decades.map((decade, index) => {
          return (
            <div
              key={index}
              onClick={() => onChange(decade, index)}
              className={`timeline__selector_item grey ${
                Number(decade) === Number(current) ? 'active' : ''
              } `}
              style={{
                width: `${
                  Number(decade) === Number(current) ? width * 1.5 : width
                }% `,
              }}
            >
              <div
                className="grey_curve left"
                style={{
                  transform: `translateX(${
                    (Math.abs(
                      10 * (index - decades.indexOf(current.toString()))
                    ) -
                      10 * 3) *
                    (Number(decade) < Number(current) ? 1 : -1)
                  }%)`,
                }}
              >
                <GreyCurveIcon
                  className={`${
                    Number(decade) < Number(current) ? 'left' : 'right'
                  }`}
                />
              </div>
              <div
                className="grey_curve right"
                style={{
                  transform: `translateX(${
                    (Math.abs(
                      10 * (index - decades.indexOf(current.toString()))
                    ) -
                      10 * 3) *
                    (Number(decade) < Number(current) ? 1 : -1)
                  }%)`,
                }}
              >
                <GreyCurveIcon
                  className={`${
                    Number(decade) < Number(current) ? 'left' : 'right'
                  }`}
                />
              </div>
              <div
                className="grey_curve "
                style={{
                  transform: `translateX(${
                    (Math.abs(
                      10 * (index - decades.indexOf(current.toString()))
                    ) -
                      10 * 3) *
                    (Number(decade) < Number(current) ? 1 : -1)
                  }%)`,
                }}
              >
                <GreyCurveIcon
                  className={`${
                    Number(decade) < Number(current) ? 'left' : 'right'
                  }`}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="timeline__selector">
        {decades.map((decade, index) => {
          return (
            <div
              key={index}
              onClick={() => onChange(decade, index)}
              className={`timeline__selector_item green ${
                Number(decade) === Number(current) ? 'active' : ''
              } `}
              style={{
                width: `${
                  Number(decade) === Number(current) ? width * 1.5 : width
                }%`,
                minWidth: `${
                  Number(decade) === Number(current) ? 8 * 1.5 : 8
                }rem`,
              }}
            >
              <GreenCurveIcon className="green_curve" />
              <span>{decade}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Selector;
