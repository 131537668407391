import React, { useState, useEffect } from "react";
import DropDownList from "../../../baseComponents/DropDownList";
import Loader from "../../../baseComponents/Loader";

import { getJudges } from "../../../services/data";

import { ReactComponent as CloseBtn } from "../../../assets/images/close_btn.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/search_btn.svg";

const Judge = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [judges, setJudges] = useState([]);
  const [list, setList] = useState([]);
  const [years, setYears] = useState({});
  const [year, setYear] = useState("");
  const [search, setSearch] = useState("");
  const [institution, setInstitution] = useState("");
  const [institutions, setInstitutions] = useState({});
  const [type, setType] = useState("");
  const [types, setTypes] = useState({});

  const getList = ({
    items = [],
    yearFilter = year,
    institutionFilter = institution,
    typeFilter = type,
  }) => {
    const yearCondition = !!yearFilter.trim().length;
    const institutionCondition = !!institutionFilter.trim().length;
    const typeCondition = !!typeFilter.trim().length;
    items = items.filter((item) => {
      // eslint-disable-next-line
      let year = yearCondition ? item.metas.extra_year == yearFilter : true;
      // eslint-disable-next-line
      let institution = institutionCondition
        ? item.metas.extra_institution === institutionFilter
        : true;
      // eslint-disable-next-line
      let type = typeCondition
        ? item.metas.extra_type_of_jury === typeFilter
        : true;
      return year && institution && type;
    });
    setList(items);
  };

  const onYearChange = (newYear) => {
    setYear(newYear);
    getList({ items: judges, yearFilter: newYear });
  };

  const onInstitutionChange = (newInstitution) => {
    setInstitution(newInstitution);
    getList({ items: judges, institutionFilter: newInstitution });
  };

  const onTypeChange = (newType) => {
    setType(newType);
    getList({ items: judges, typeFilter: newType });
  };

  const updateData = (searchText = search) => {
    setIsFetching(true);
    getJudges(searchText)
      .then((res) => {
        setJudges(res.items);
        setYears(res.years);
        setInstitutions(res.institutions);
        setTypes(res.types);
        getList({ items: res.items });
      })
      .finally(() => setIsFetching(false));
  };

  useEffect(() => {
    updateData();
    // eslint-disable-next-line
  }, []);

  if (isFetching) return <Loader />;

  return (
    <div className="judge__table_container">
      <table className="judge__table"></table>
      <div className="judge__table_wrapper">
        <table className="judge__table">
          <thead>
            <tr className="judge__filters">
              <th className="column_name">
                <div className="search_bar__container">
                  <input
                    value={search}
                    onFocus={() => {
                      let viewport = document.querySelector(
                        "meta[name=viewport]"
                      );
                      viewport.setAttribute(
                        "content",
                        `${viewport.content} , height=${window.innerHeight}`
                      );
                    }}
                    onBlur={() => {
                      var viewport = document.querySelector(
                        "meta[name=viewport]"
                      );
                      setTimeout(() => {
                        viewport.setAttribute(
                          "content",
                          `${viewport.content}, height=device-height`
                        );
                      }, 500);
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") updateData(search);
                    }}
                  />
                  <div>
                    <SearchIcon onClick={() => updateData(search)} />
                    {search.length > 0 && (
                      <CloseBtn
                        className="judge__close_btn"
                        onClick={() => {
                          setSearch("");
                          updateData("");
                        }}
                      />
                    )}
                  </div>
                </div>
              </th>
              <th className="column_year">
                <DropDownList
                  value={year}
                  placeholder="Año"
                  items={years}
                  onValueChange={onYearChange}
                />
              </th>
              <th className="column_institution">
                <DropDownList
                  value={institution}
                  placeholder="Institución"
                  items={institutions}
                  onValueChange={onInstitutionChange}
                />
              </th>
              <th className="column_type">
                <DropDownList
                  value={type}
                  placeholder="Tipo"
                  items={types}
                  onValueChange={onTypeChange}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((judge, index) => (
              <tr className="judge__table_row" key={index}>
                <td className="column_name">
                  <h3>{judge.title}</h3>
                </td>
                <td className="column_year">
                  <p>{judge.metas.extra_year}</p>
                </td>
                <td className="column_institution">
                  <p>{judge.metas.extra_institution}</p>
                </td>
                <td className="column_type">
                  <p>{judge.metas.extra_type_of_jury}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Judge;
