import React, { useState, useEffect } from "react";
import NotFound from "../../baseComponents/NotFound";
import BackButton from "../../baseComponents/BackButton";
import Loader from "../../baseComponents/Loader";
import Lottie from "lottie-react";

import { replaceBR } from "../../utils/texts";
import { useNavigate, useParams } from "react-router-dom";
import { getWinner } from "../../services/data";

import engines from "../../assets/images/engines.json";

import "./index.css";

const GraphView = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [info, setInfo] = useState(null);
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const [isHidden, setHidden] = useState(false);

  const handleBack = () => {
    setHidden(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  useEffect(() => {
    getWinner(id)
      .then((res) => setInfo(res))
      .finally(() => setIsFetching(false));
    // eslint-disable-next-line
  }, []);

  if (isFetching) return <Loader />;
  if (!Boolean(info)) return <NotFound />;
  return (
    <div className={`winner_view ${isHidden ? "hidden" : ""}`}>
      <div className="winner_view__back">
        <BackButton onClick={handleBack} />
      </div>
      <div className="winner_view__content">
        <h1>
          <div className="mask">
            <div
              className={`text ${
                info.metas.extra_author.length > 30 ? "small" : ""
              }`}
            >
              {info.metas.extra_author}
            </div>
          </div>
        </h1>
        <div className="winner_view__content_grid">
          <div className="winner_view__content_column title">
            <div className="mask">
              <p>{info.title}</p>
            </div>
          </div>
          <div className="winner_view__content_column info">
            <div className="winner_view__info">
              <span>Ganador</span>
              <p>{info.metas.extra_place}</p>
            </div>
            <div className="winner_view__info">
              <span>Categoría</span>
              <p>{info.metas.extra_category}</p>
            </div>
            <div className="winner_view__info">
              <span>Año</span>
              <p>{info.metas.extra_year}</p>
            </div>
          </div>
          <Lottie animationData={engines} className="winner_view_decorative" />
        </div>
        <div className="winner_view__tags">
          <h3>TEMÁTICA</h3>
          <p>{replaceBR(info.metas.extra_main_theme)}</p>
        </div>
      </div>
    </div>
  );
};

export default GraphView;
