import axios from "./instance";

export const getSingleInterviewsList = () => {
  const data = new FormData();
  data.append("posttype", "entrevista");
  data.append("order", "asc");
  data.append("orderby", "date");
  data.append("meta_key", "");
  data.append("ppp", "50");
  data.append("paged", "1");
  data.append("search", "");
  data.append("show_metas", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => (res.data instanceof Array ? res.data : []))
    .then((res) => res.chunk(8));
};

export const getInterview = (slug = "") => {
  const data = new FormData();
  data.append("postid", slug);
  data.append("posttype", "entrevista");
  data.append("show_metas", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => (res.data && res.data.id ? res.data : null));
};

export const getThematicInterviewsList = () => {
  const data = new FormData();
  data.append("posttype", "video");
  data.append("order", "asc");
  data.append("orderby", "date");
  data.append("meta_key", "");
  data.append("ppp", "50");
  data.append("paged", "1");
  data.append("search", "");
  data.append("category", "");
  data.append("show_metas", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => (res.data instanceof Array ? res.data : []))
    .then((res) => res.chunk(3));
};

export const getThematic = (slug = "") => {
  const data = new FormData();
  data.append("postid", slug);
  data.append("posttype", "video");
  data.append("show_metas", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => (res.data && res.data.id ? res.data : null));
};
