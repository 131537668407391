import React, { memo, useEffect, useRef, useState } from "react";
import Selector from "./CurvesSelector";

import { getGraphs } from "../../../services/data";

import { ReactComponent as ArrowPrev } from "../../../assets/images/arrow_btn_prev.svg";
import { ReactComponent as ArrowNext } from "../../../assets/images/arrow_btn_next.svg";
import Loader from "../../../baseComponents/Loader";
import useWindowSize from "../../../utils/useWindowSize";

const INITIAL_DECADE = 1950;

const GraphItem = memo(({ title = "", value = 1, max = 100, color }) => {
  const percent = (value * 100) / max;
  return (
    <div className={`graph_bar__item ${!value ? "hidden" : ""}`}>
      <div className="graph_bar__item_rotate">
        <p style={{ color }}>
          {title} <span className="tab" /> {value}
        </p>
        <div className="bar__container" style={{}}>
          <div
            className="bar"
            style={{ height: `${percent}%`, background: color, width: "100%" }}
          />
        </div>
      </div>
    </div>
  );
});

const Research = ({
  onDecadesLoad = () => null,
  onDecadeChange = () => null,
  currentDecade,
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const [decades, setDecades] = useState({});
  const [decadeIndex, setDecadeIndex] = useState(0);
  const [decade, setDecade] = useState(INITIAL_DECADE);
  const [prevDecade, setPrevDecade] = useState(INITIAL_DECADE);
  const windowSize = useWindowSize();
  const graph = useRef();
  const colors = ["#000000", "#00A09E", "#DD3B29"];
  let currentColor = 0;

  useEffect(() => {
    getGraphs()
      .then((res) => {
        const decade = Object.keys(res)[0];
        setDecades(res);
        setDecade(decade);
        onDecadesLoad(res);
      })
      .finally(() => setIsFetching(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (graph.current) {
      if (windowSize.width < 1200) {
        graph.current.style.transform = `translateY(${-graph.current
          .clientHeight}px) rotate(90deg)`;
      } else {
        graph.current.style.transform = "";
      }
    }
  });

  useEffect(() => {
    if (decade !== Number(currentDecade)) onDecadeChange(Number(decade));
    // eslint-disable-next-line
  }, [decade]);

  useEffect(() => {
    setDecade(currentDecade);
  }, [currentDecade]);

  useEffect(() => {
    return () => {
      onDecadeChange(1950);
      onDecadesLoad([]);
    };
    // eslint-disable-next-line
  }, []);

  if (isFetching) return <Loader />;

  const changeDecade = (nextDecade, index) => {
    setPrevDecade(decade);
    setDecade(nextDecade);
    setDecadeIndex(index);
  };

  const data = decades[decade];
  return (
    <React.Fragment>
      <Selector
        decades={Object.keys(decades)}
        decadeIndex={decadeIndex}
        current={decade}
        prev={prevDecade}
        onChange={changeDecade}
      />
      <div className="data__container">
        <ArrowPrev
          onClick={() => {
            let nextDecade =
              decadeIndex - 1 < 0
                ? Object.keys(decades).length - 1
                : decadeIndex - 1;
            changeDecade(Object.keys(decades)[nextDecade], nextDecade);
          }}
          className="graph_arrow__btn left"
        />
        <div className="graph_bar__decade_container">
          <div className="graph_bar__decade" ref={graph}>
            {Object.values(data.values).map((value, index) => {
              currentColor = value.total <= 3 ? 0 : value.total <= 6 ? 1 : 2;
              return (
                <GraphItem
                  key={index}
                  value={value.total}
                  max={data.max}
                  title={value.title}
                  color={colors[currentColor]}
                />
              );
            })}
          </div>
        </div>
        <ArrowNext
          onClick={() => {
            let nextDecade =
              decadeIndex + 1 >= Object.keys(decades).length
                ? 0
                : decadeIndex + 1;
            changeDecade(Object.keys(decades)[nextDecade], nextDecade);
          }}
          className="graph_arrow__btn right"
        />
      </div>
    </React.Fragment>
  );
};

export default Research;
