import axios from "./instance";

export const getGraphs = () => {
  const data = new FormData();
  data.append("grafic", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => (res.data ? res.data : {}))
    .then((res) => {
      const keys = Object.keys(res);
      let decades = {};
      for (let decade of keys) {
        let max = Object.values(res[decade]).map((item) => item.total);
        max = Math.max(...max);
        decades[decade] = { max, values: res[decade] };
      }
      return decades;
    });
};

export const getDecades = () => {
  const data = new FormData();
  data.append("decade", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => res.data);
};

export const getWinner = (slug = "") => {
  const data = new FormData();
  data.append("postid", slug);
  data.append("posttype", "ganador_banamex");
  data.append("show_metas", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => {
      if (!res.data && !res.data.id) return null;
      const metas = res.data.metas instanceof Array ? res.data.metas[0] : {};
      return { ...res.data, metas };
    });
};

export const getWinners = () => {
  const data = new FormData();
  data.append("posttype", "ganador_banamex");
  data.append("order", "desc");
  data.append("orderby", "meta_value");
  data.append("meta_key", "extra_year");
  data.append("ppp", "500");
  data.append("paged", "1");
  data.append("search", "");
  data.append("show_metas", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => (res.data instanceof Array ? res.data : []))
    .then((res) => {
      let decades = {};
      let decadeList = [];
      let mobileDecadeList = [];
      for (let item of res) {
        item.metas = item.metas[0];
        let decade = item.metas.extra_decade;
        let items = decades[decade] ? decades[decade] : [];
        decades[decade] = [...items, item];
      }
      for (let decade of Object.keys(decades)) {
        let items = decades[decade]
          .sort(
            (a, b) => Number(a.metas.extra_year) - Number(b.metas.extra_year)
          )
          .chunk(8)
          .map((item, index) => ({
            isMain: index === 0,
            decade,
            values: item,
          }));
        decadeList = [...decadeList, ...items];

        let mobileItems = decades[decade]
          .sort(
            (a, b) => Number(a.metas.extra_year) - Number(b.metas.extra_year)
          )
          .chunk(4)
          .map((item, index) => ({
            isMain: index === 0,
            decade,
            values: item,
          }));
        mobileDecadeList = [...mobileDecadeList, ...mobileItems];
      }
      return [decadeList, mobileDecadeList];
    });
};

export const getJudges = (search = "") => {
  const data = new FormData();
  data.append("posttype", "jurado");
  data.append("order", "asc");
  data.append("orderby", "meta_value");
  data.append("meta_key", "extra_year");
  data.append("ppp", "500");
  data.append("paged", "1");
  data.append("search", search);
  data.append("show_metas", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => (res.data instanceof Array ? res.data : []))
    .then((items) => {
      let types = {};
      let years = {};
      let institutions = {};
      for (let item of items) {
        item.metas = item.metas[0];
        let year = item.metas.extra_year;
        let institution = item.metas.extra_institution;
        let type = item.metas.extra_type_of_jury;
        types[type] = type;
        years[year] = year;
        institutions[institution] = institution;
      }
      return {
        types: Object.values(types),
        years: Object.values(years),
        institutions: Object.values(institutions),
        items,
      };
    });
};

export const getWinnersTable = (search = "") => {
  const data = new FormData();
  data.append("posttype", "ganador_banamex");
  data.append("order", "asc");
  data.append("orderby", "meta_value");
  data.append("meta_key", "extra_year");
  data.append("ppp", "500");
  data.append("paged", "1");
  data.append("search", search);
  data.append("show_metas", "1");
  return axios({
    url: "/",
    method: "post",
    data,
  })
    .then((res) => res.data)
    .then((res) => (res.data instanceof Array ? res.data : []))
    .then((items) => {
      let places = {};
      let years = {};
      let thematics = {};
      for (let item of items) {
        item.metas = item.metas[0];
        let place = item.metas.extra_place;
        let year = item.metas.extra_year;
        let thematic = item.metas.extra_main_theme;
        places[place] = place;
        years[year] = year;
        thematics[thematic] = thematic;
      }
      return {
        places: Object.values(places),
        years: Object.values(years),
        thematics: Object.values(thematics),
        items,
      };
    });
};
