import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeaderBack } from "../../baseComponents/BackButton";
import { getUrl } from "../../utils/routes";

import microphone from "../../assets/images/microphone.svg";
import folders from "../../assets/images/folders.svg";
import connector from "../../assets/images/connector_horizontal.svg";

import "./index.css";

const Interviews = (props) => {
  const navigate = useNavigate();
  const [isHidden, setHidden] = useState(false);

  const handleBtn = (i) => {
    switch (i) {
      case 0:
        setHidden(true);
        setTimeout(() => {
          navigate(getUrl("thematic_list"));
        }, 1000);
        break;
      case 1:
        setHidden(true);
        setTimeout(() => {
          navigate(getUrl("interview_list"));
        }, 1000);
        break;

      default:
        break;
    }
  };

  const handleBack = () => {
    setHidden(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  return (
    <div className={`interviews ${isHidden ? "hidden" : ""}`}>
      <div className="interviews__back">
        <HeaderBack
          onClick={handleBack}
          onNavigateHome={() => setHidden(true)}
        />
      </div>
      <div className="interviews__content">
        <div className="interviews__header">
          <h1>
            <div className="mask">
              <div className="text">Entrevistas</div>
            </div>
          </h1>
          <div className="subtitle">
            <div className="mask">
              <h3>¿Qué opinan los ganadores?</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="interviews__button_container right">
        <div
          className={`interviews__button`}
          onClick={(event) => {
            if (event.currentTarget.disabled) return;
            event.currentTarget.disabled = true;
            handleBtn(0);
          }}
        >
          <img
            className="interviews__button_icon"
            src={folders}
            alt="Micrófono"
          />
        </div>
        <div className="interviews__button_container_text">
          <div className="interviews__button_text">
            <p>Por tema</p>
          </div>
        </div>
      </div>
      <div className="interviews__button_container left">
        <div
          className={`interviews__button`}
          onClick={(event) => {
            if (event.currentTarget.disabled) return;
            event.currentTarget.disabled = true;
            handleBtn(1);
          }}
        >
          <img
            className="interviews__button_icon"
            src={microphone}
            alt="Micrófono"
          />
        </div>
        <img
          className="interviews__button_connector"
          src={connector}
          alt="conector"
        />
        <div className="interviews__button_container_text">
          <div className="interviews__button_text">
            <p>Individuales</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interviews;
