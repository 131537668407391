import axios from "axios";
let baseURL = process.env.REACT_APP_SERVER_URL_BASE || "";

if (process.env.REACT_APP_DESKTOP_MODE && process.env.NODE_ENV === "production")
  baseURL = process.env.REACT_APP_SERVER_URL_OFFLINE_URL;

const instance = axios.create({
  baseURL,
});

export default instance;
