import React, { useState, useEffect } from 'react';
import Judge from './components/Judge';
import Research from './components/Research';
import TimeLine from './components/TimeLine';
import Layout from './components/Layout';

import './index.css';
import Winner from './components/Winners';

const pages = {
  timeline: {
    title: 'Línea del tiempo',
    description:
      'Recorre la historia del Premio y conoce a los ganadores y títulos de sus investigaciones.',
    input: true,
  },
  judge: {
    title: 'Jurado',
    description:
      'Notables jurados han estado detrás de la selección de los trabajos ganadores del Premio. Conoce sus nombres e instituciones.',
  },
  winners: {
    input: true,
    title: 'Ganadores',
    description:
      'En esta búsqueda libre podrás saber más de las investigaciones ganadoras y sus autores. ',
  },
  research: {
    title: 'Temas de investigación',
    description:
      'Descubre qué intereses temáticos han predominado a lo largo de la historia del Premio.',
  },
};

const GraphsView = (props) => {
  const [text, setText] = useState('');
  const [lastSearch, setLastSearch] = useState(new Date());
  const [mode, setMode] = useState('timeline');
  const [decade, setDecade] = useState('1950');
  const [decades, setDecades] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('winners_filters')) {
      setMode('winners');
    }
    if (localStorage.getItem('layout_search_text')) {
      setText(localStorage.getItem('layout_search_text'));
      localStorage.removeItem('layout_search_text');
    }
  }, []);

  const onChangMode = (newMode) => {
    if (mode !== newMode) setMode(newMode);
  };

  const onDecadeChange = (newDecade) => {
    if (decade !== newDecade) setDecade(newDecade);
  };

  const onDecadesLoad = (newDecades) => {
    if (decades !== newDecades) setDecades(newDecades);
  };

  const getTitle = () => (pages[mode] ? pages[mode].title : '');

  const getDescription = () => (pages[mode] ? pages[mode].description : '');

  const getComponent = () => {
    switch (mode) {
      case 'timeline':
        return <TimeLine />;
      case 'research':
        return (
          <Research
            onDecadesLoad={onDecadesLoad}
            onDecadeChange={onDecadeChange}
            currentDecade={decade}
          />
        );
      case 'judge':
        return <Judge />;
      case 'winners':
        return <Winner lastSearch={lastSearch} search={text} />;
      default:
        return null;
    }
  };

  return (
    <Layout
      displayInput={pages[mode].input}
      inputText={text}
      onChangeText={(text) => {
        localStorage.setItem('layout_search_text', text);
        setText(text);
      }}
      onSearch={() => {
        setLastSearch(new Date());
        setMode('winners');
      }}
      title={getTitle()}
      description={getDescription()}
      onChangMode={onChangMode}
      mode={mode}
      decade={decade}
      decades={decades}
      onDecadeChange={onDecadeChange}
    >
      {getComponent()}
    </Layout>
  );
};

export default GraphsView;
