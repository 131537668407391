import React from 'react';

import doubleBig from '../assets/images/gear_big.svg';

const Loader = () => {
  return (
    <div className="loader">
      <img src={doubleBig} alt="engrane doble" className="loader__icon" />
    </div>
  );
};

export default Loader;
