import React, { useEffect, useState } from "react";
import mask from "../../../assets/images/mask.jpg";

const Item = ({
  item = null,
  index = Math.random(),
  limit = 1,
  onClick = () => null,
}) => {
  const [activeClass, setActiveClass] = useState("");
  useEffect(() => {
    const activeTimeout = setTimeout(() => {
      setActiveClass("active");
    }, 80);
    return () => {
      clearTimeout(activeTimeout);
    };
  }, [index]);

  if (!item) return null;

  let thumbnail =
    item.metas instanceof Array
      ? item.metas.find((meta) => meta.extra_thumbnail)
      : {};
  thumbnail = thumbnail.extra_thumbnail ? thumbnail.extra_thumbnail : mask;

  return (
    <div
      className={`thematic_interviews__item ${activeClass}`}
      onClick={onClick}
    >
      <div className="thematic_interviews__item_avatar">
        <img src={thumbnail} alt="Foto de perfil" />
      </div>
      <div className="thematic_interviews__item_text">
        <p className="thematic_interviews__item_title">{item.title}</p>
      </div>
    </div>
  );
};

export default Item;
