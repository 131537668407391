import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { HeaderBack } from "../../baseComponents/BackButton";
import Item from "./components/Item";
import Loader from "../../baseComponents/Loader";

import { useNavigate } from "react-router-dom";
import { getSingleInterviewsList } from "../../services/interviews";
import { getUrl } from "../../utils/routes";

import connector from "../../assets/images/connector_horizontal.svg";
import { ReactComponent as ArrowPrev } from "../../assets/images/arrow_btn_prev.svg";
import { ReactComponent as ArrowNext } from "../../assets/images/arrow_btn_next.svg";

import "./index.css";
import useWindowSize from "../../utils/useWindowSize";

const SlideView = ({ items, handleBtn = () => null }) => {
  return (
    <div className="single_interview__slide">
      {items.map((item, index) => {
        const onClick = () => {
          handleBtn(item);
        };
        return (
          <Item
            key={index}
            onClick={onClick}
            item={item}
            index={index}
            isLast={index === items.length - 1}
          />
        );
      })}
    </div>
  );
};

const SingleInterviewList = (props) => {
  const [isClickEnable, setIsClickEnable] = useState(true);
  const [list, setList] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const [isHidden, setHidden] = useState(false);
  const [listMobile, setListMobile] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const windowSize = useWindowSize();
  let longList = [];
  let smallList = [];

  useEffect(() => {
    getSingleInterviewsList()
      .then((list) => {
        setList(list);

        list.forEach((e) => {
          e.forEach((el) => {
            longList.push(el);
          });
        });
        for (let i = 0; i < longList.length; i += 6) {
          smallList.push(longList.slice(i, i + 6));
        }
        setListMobile(smallList);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsFetching(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setCurrentList(windowSize.width < 992 ? listMobile : list);
  }, [list, listMobile, windowSize]);

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const onPrevSlide = () => {
    if (containerRef.current) containerRef.current.slickPrev();
  };
  const onNextSlide = () => {
    if (containerRef.current) containerRef.current.slickNext();
  };

  const handleBtn = (item) => {
    if (!isClickEnable) return;
    setIsClickEnable(!isClickEnable);
    setHidden(true);
    setTimeout(() => {
      navigate(`${getUrl("interview_list")}/${item.id}`);
    }, 1500);
  };

  const handleBack = () => {
    setHidden(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  if (isFetching) return <Loader />;

  return (
    <div className={`single_interview ${isHidden ? "hidden" : ""}`}>
      <div className="single_interview__back">
        <HeaderBack
          onClick={handleBack}
          onNavigateHome={() => setHidden(true)}
        />
      </div>
      <div className="single_interview__header">
        <h1>
          <div className="mask">
            <div className="text">
              <b>Entrevistas</b> individuales
            </div>
          </div>
        </h1>
      </div>
      <div className="shape-container left">
        <img
          src={connector}
          alt="conector"
          className="single_interview__shape left"
        />
      </div>
      <div className="single_interview__content">
        {Boolean(currentList.length > 1) && (
          <ArrowPrev
            onClick={onPrevSlide}
            className="single_interview__btn left"
          />
        )}
        <Slider
          {...settings}
          className="single_interview__items"
          ref={containerRef}
        >
          {currentList.map((item, index) => (
            <SlideView key={index} items={item} handleBtn={handleBtn} />
          ))}
        </Slider>
        {Boolean(currentList.length > 1) && (
          <ArrowNext
            onClick={onNextSlide}
            className="single_interview__btn right"
          />
        )}
      </div>
      <div className="shape-container right">
        <img
          src={connector}
          alt="conector"
          className="single_interview__shape right"
        />
      </div>
    </div>
  );
};

export default SingleInterviewList;
