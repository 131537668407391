import React, { useState, useEffect } from "react";
import DropDownList from "../../../baseComponents/DropDownList";
import Loader from "../../../baseComponents/Loader";

import { getWinnersTable } from "../../../services/data";
import { getUrl } from "../../../utils/routes";
import { useNavigate } from "react-router-dom";
import { replaceBR } from "../../../utils/texts";

import { ReactComponent as CloseBtn } from "../../../assets/images/close_btn.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/search_btn.svg";

const Winners = () => {
  const [isClickEnable, setIsClickEnable] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [search, setSearch] = useState("");
  const [judges, setJudges] = useState([]);
  const [list, setList] = useState([]);
  const [years, setYears] = useState({});
  const [year, setYear] = useState("");
  const [place, setPlace] = useState("");
  const [places, setPlaces] = useState({});
  const [thematic, setThematic] = useState("");
  const [thematics, setThematics] = useState({});
  const navigate = useNavigate();

  const getList = ({
    items = [],
    yearFilter = year,
    placeFilter = place,
    thematicFilter = thematic,
  }) => {
    let hasFilters = localStorage.getItem("winners_filters");
    if (hasFilters) {
      hasFilters = JSON.parse(hasFilters);
      yearFilter = hasFilters.year;
      placeFilter = hasFilters.place;
      thematicFilter = hasFilters.thematic;
      setYear(hasFilters.year);
      setPlace(hasFilters.place);
      setThematic(hasFilters.thematic);
      localStorage.removeItem("winners_filters");
    }

    const yearCondition = !!yearFilter.trim().length;
    const placeCondition = !!placeFilter.trim().length;
    const thematicCondition = !!thematicFilter.trim().length;
    items = items.filter((item) => {
      // eslint-disable-next-line
      let year = yearCondition ? item.metas.extra_year == yearFilter : true;
      // eslint-disable-next-line
      let institution = placeCondition
        ? item.metas.extra_place === placeFilter
        : true;
      // eslint-disable-next-line
      let type = thematicCondition
        ? item.metas.extra_main_theme === thematicFilter
        : true;
      return year && institution && type;
    });
    setList(items);
  };

  const onYearChange = (newYear) => {
    setYear(newYear);
    getList({ items: judges, yearFilter: newYear });
  };

  const onPlaceChange = (newPlace) => {
    setPlace(newPlace);
    getList({ items: judges, placeFilter: newPlace });
  };

  const onThematicChange = (newThematic) => {
    setThematic(newThematic);
    getList({ items: judges, thematicFilter: newThematic });
  };

  const updateData = (searchText = search) => {
    setIsFetching(true);
    getWinnersTable(searchText)
      .then((res) => {
        setYears(res.years);
        setPlaces(res.places);
        setThematics(res.thematics);
        setJudges(res.items);
        getList({ items: res.items });
      })
      .finally(() => setIsFetching(false));
  };

  const onClickHandler = (item) => {
    if (!isClickEnable) return;
    setIsClickEnable(!isClickEnable);
    navigate(`${getUrl("timeline")}/${item.id}`);
    localStorage.setItem(
      "winners_filters",
      JSON.stringify({
        year,
        place,
        thematic,
      })
    );
  };

  useEffect(() => {
    updateData();
    // eslint-disable-next-line
  }, []);

  if (isFetching) return <Loader />;

  return (
    <div className="judge__table_container">
      <table className="judge__table"></table>
      <div className="judge__table_wrapper">
        <table className="judge__table">
          <thead>
            <tr>
              <th className="column_name">
                <div className="search_bar__container">
                  <input
                    value={search}
                    onFocus={() => {
                      let viewport = document.querySelector(
                        "meta[name=viewport]"
                      );
                      viewport.setAttribute(
                        "content",
                        `${viewport.content} , height=${window.innerHeight}`
                      );
                    }}
                    onBlur={() => {
                      var viewport = document.querySelector(
                        "meta[name=viewport]"
                      );
                      setTimeout(() => {
                        viewport.setAttribute(
                          "content",
                          `${viewport.content}, height=device-height`
                        );
                      }, 500);
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") updateData(search);
                    }}
                  />
                  <div>
                    <SearchIcon onClick={() => updateData(search)} />
                    {search.length > 0 && (
                      <CloseBtn
                        className="judge__close_btn"
                        onClick={() => {
                          setSearch("");
                          updateData("");
                        }}
                      />
                    )}
                  </div>
                </div>
              </th>
              <th>
                <DropDownList
                  value={year}
                  placeholder="Año"
                  items={years}
                  onValueChange={onYearChange}
                />
              </th>
              <th>
                <h4>Titulo</h4>
              </th>
              <th>
                <DropDownList
                  value={place}
                  placeholder="Lugar"
                  items={places}
                  onValueChange={onPlaceChange}
                />
              </th>
              <th>
                <DropDownList
                  value={thematic}
                  placeholder="Temática sugerida"
                  items={thematics}
                  onValueChange={onThematicChange}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr
                className="judge__table_row winner_table"
                key={index}
                onClick={() => {
                  onClickHandler(item);
                }}
              >
                <td className="column_name">
                  <h3>{item.metas.extra_author}</h3>
                </td>
                <td>
                  <p>{item.metas.extra_year}</p>
                </td>
                <td>
                  <p>{item.title}</p>
                </td>
                <td>
                  <p>{item.metas.extra_place}</p>
                </td>
                <td>
                  <p>{replaceBR(item.metas.extra_main_theme)}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Winners;
