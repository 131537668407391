import React from 'react'
import { HomeButton } from './BackButton'

const NotFound = props => {
  return <div className="not_found">
    <div className="container">
      <h1>404</h1>
      <h2>No encontrado</h2>
      <HomeButton />
    </div>
  </div>
}

export default NotFound