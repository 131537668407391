import React, { useEffect, useState } from "react";
import { HeaderBack } from "../../../baseComponents/BackButton";
import ModeSelector from "./ModeSelector";

import { useNavigate } from "react-router-dom";
import useWindowSize from "../../../utils/useWindowSize";

const Layout = ({
  title = "",
  description = "",
  onChangMode = () => null,
  children,
  mode,
  decade,
  decades,
  onDecadeChange = () => null,
}) => {
  const navigate = useNavigate();
  const [isHidden, setHidden] = useState(false);
  const windowSize = useWindowSize();

  const handleBack = () => {
    setHidden(true);
    setTimeout(() => {
      navigate(-1);
    }, 1000);
  };

  useEffect(() => {
    if (windowSize.width < 1500) {
    }
  }, [windowSize]);

  return (
    <div className={`graph_view ${mode} ${isHidden ? "hidden" : ""}`}>
      <div className="graph_view__back">
        <HeaderBack
          onClick={handleBack}
          onNavigateHome={() => setHidden(true)}
        />
      </div>
      <div className="graph_view__header">
        <h1>
          <div className="mask">
            <div className="text">{title}</div>
          </div>
        </h1>
        <div className="graph_view__description_paragraphs">
          <div className="mask">
            <p>{description}</p>
          </div>
        </div>
      </div>
      <div className="graph_view__description">
        <ModeSelector
          mode={mode}
          onChangMode={onChangMode}
          decade={decade}
          decades={decades}
          onDecadeChange={onDecadeChange}
        />
      </div>
      <div className="graph_view__content">{children}</div>
    </div>
  );
};

export default Layout;
