import React, { useState } from 'react'

import { ReactComponent as Chevron } from '../assets/images/chevron_down.svg'

const DropDownList = ({
  items = {},
  value = '',
  placeholder = '',
  onValueChange = () => null
}) => {
  const [isListVisible, setIsListVisible] = useState(false)
  const onClick = item => {
    onValueChange(item)
    setIsListVisible(false)
  }
  return <div className='dropdown'>
    <div className='dropdown_header' onClick={() => setIsListVisible(!isListVisible)}>
      <h4>{value.trim().length ? value : placeholder}</h4>
      <Chevron />
    </div>
    {
      isListVisible && <div className='dropdown_content'>
        <div className='dropdown_item' onClick={() => onClick('')}>
          <p>{placeholder}</p>
        </div>
        {
          items.map(item => <div key={item} className='dropdown_item' onClick={() => onClick(item)}>
            <p>{item}</p>
          </div>)
        }
      </div>
    }
  </div>
}

export default DropDownList