import React, { memo, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import NotFound from "../../../baseComponents/NotFound";
import Selector from "./CurvesSelector";

import { useNavigate } from "react-router-dom";
import { getDecades, getWinners } from "../../../services/data";
import { getUrl } from "../../../utils/routes";

import { ReactComponent as Arrow } from "../../../assets/images/arrow_button.svg";
import { ReactComponent as ArrowPrev } from "../../../assets/images/arrow_btn_prev.svg";
import { ReactComponent as ArrowNext } from "../../../assets/images/arrow_btn_next.svg";
import Loader from "../../../baseComponents/Loader";
import useWindowSize from "../../../utils/useWindowSize";

const INITIAL_DECADE = 1950;
let timelineDragging = false;

const DecadeSlide = memo(({ slide, index, navigate, mobile }) => {
  const [isClickEnable, setIsClickEnable] = useState(true);

  // eslint-disable-next-line no-useless-computed-key
  let props = { ["data-decade"]: slide.decade };

  if (slide.isMain) {
    // eslint-disable-next-line no-useless-computed-key
    props = { ...props, id: slide.decade, ["data-index"]: index };
  }
  return (
    <div {...props} className={`graph_bar__decade_container slide_${index}`}>
      <div className="timeline__slide_content">
        {slide.values.map((item) => {
          let title =
            typeof item.metas.extra_short_author === "string"
              ? item.metas.extra_short_author
              : "";
          if (!title.length) {
            title =
              item.metas.extra_author.length > 30
                ? `${item.metas.extra_author.substring(0, 27)}...`
                : item.metas.extra_author;
          }
          return (
            <div
              key={item.id}
              className={
                "timeline__slide_container_item" + (mobile ? " mobile" : "")
              }
              onClick={() => {
                if (!isClickEnable) return;
                setIsClickEnable(!isClickEnable);
                if (!timelineDragging) {
                  document.querySelector(".graph_view").classList.add("hidden");
                  setTimeout(() => {
                    navigate(`${getUrl("timeline")}/${item.id}`);
                    localStorage.setItem("timeline_decade", `${slide.decade}`);
                    localStorage.setItem("timeline_index", `${index}`);
                  }, 1000);
                }
              }}
            >
              <h3 dangerouslySetInnerHTML={{ __html: title }} />
              <div className="timeline__slide_container_button">
                <p>{item.metas.extra_year}</p>
                <Arrow />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});

const TimeLine = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [decades, setDecades] = useState({});
  const [decadeList, setDecadeList] = useState([]);
  const [decadeIndex, setDecadeIndex] = useState(0);
  const [decade, setDecade] = useState(INITIAL_DECADE);
  const [prevDecade, setPrevDecade] = useState(INITIAL_DECADE);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const windowSize = useWindowSize();

  useEffect(() => {
    getDecades()
      .then((res) => {
        const hasStorageDecade = localStorage.getItem("timeline_decade");
        const decade = hasStorageDecade
          ? hasStorageDecade
          : Object.keys(res)[0];
        if (hasStorageDecade) localStorage.removeItem("timeline_decade");
        setDecades(res);
        setDecade(decade);
        return getWinners();
      })
      .then((res) => {
        setDecadeList(res);
      })
      .finally(() => setIsFetching(false));
  }, []);

  useEffect(() => {
    if (!isFetching && containerRef.current) {
      const hasIndexDecade = localStorage.getItem("timeline_index");
      containerRef.current.slickGoTo(Number(hasIndexDecade));
      localStorage.removeItem("timeline_index");
    }
  }, [isFetching, containerRef]);

  useEffect(() => {
    /* 
    document.querySelectorAll('.timeline__selector > *').forEach((e) => {
      e.style.transform = `translateX(${-8.5 * decadeIndex}rem)`;
    });*/
  }, [decadeIndex]);

  if (isFetching) return <Loader />;

  if (!decadeList[0] || !decadeList[0].length) return <NotFound />;

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => (timelineDragging = false),
    beforeChange: (_, newIndex) => {
      timelineDragging = true;
      setTimeout(() => {
        timelineDragging = false;
      }, 500);
      const slide = document.querySelector(`.slide_${newIndex}`);
      if (slide && slide.getAttribute("data-decade")) {
        const index = slide.getAttribute("data-index")
          ? Number(slide.getAttribute("data-index"))
          : null;
        const nextDecade = Number(slide.getAttribute("data-decade"));
        setPrevDecade(decade);
        setDecade(nextDecade);
        if (index) setDecadeIndex(index);
      }
    },
  };

  const onPrevSlide = () => {
    if (containerRef.current) containerRef.current.slickPrev();
  };
  const onNextSlide = () => {
    if (containerRef.current) containerRef.current.slickNext();
  };

  let newDecadeList = [];
  decadeList[1].forEach((e, i) => {
    if (
      newDecadeList.length &&
      newDecadeList[newDecadeList.length - 1][0].decade === e.decade
    ) {
      newDecadeList[newDecadeList.length - 1].push(e);
    } else {
      newDecadeList.push([e]);
    }
  });

  return windowSize.width > 992 ? (
    <React.Fragment>
      <Selector
        decades={Object.keys(decades)}
        decadeIndex={decadeIndex}
        current={decade}
        prev={prevDecade}
        onChange={(nextDecade, index) => {
          const slide = document.getElementById(`${nextDecade}`);
          if (slide && slide.getAttribute("data-index")) {
            const index = Number(slide.getAttribute("data-index"));
            containerRef.current.slickGoTo(index);
          }
          setPrevDecade(decade);
          setDecade(nextDecade);
          setDecadeIndex(index);
        }}
      />
      <div className="data__container">
        <ArrowPrev onClick={onPrevSlide} className="graph_arrow__btn left" />
        <Slider
          {...settings}
          className="timeline__container"
          ref={containerRef}
        >
          {decadeList[windowSize.width > 992 ? 0 : 1].map((slide, index) => (
            <DecadeSlide
              navigate={navigate}
              key={index}
              index={index}
              slide={slide}
              mobile={windowSize.width < 992}
            />
          ))}
        </Slider>
        <ArrowNext onClick={onNextSlide} className="graph_arrow__btn right" />
      </div>
    </React.Fragment>
  ) : (
    <div className="decades_container">
      {newDecadeList.map((e, i) => (
        <div>
          <label
            className="decades_year"
            style={{ background: i % 2 === 0 ? "#00A09E" : "#E1E1E1" }}
            for={`checkbox_${i}`}
          >
            {e[0].decade}
          </label>
          <input
            type="checkbox"
            className="decades_checkbox"
            id={`checkbox_${i}`}
          />
          <div className="data__container timeline">
            <ArrowPrev
              onClick={onPrevSlide}
              className="graph_arrow__btn left"
            />
            <Slider
              {...settings}
              className="timeline__container"
              ref={containerRef}
            >
              {e.map((slide, index) => (
                <DecadeSlide
                  navigate={navigate}
                  key={index}
                  index={index}
                  slide={slide}
                  mobile={windowSize.width < 992}
                />
              ))}
            </Slider>
            <ArrowNext
              onClick={onNextSlide}
              className="graph_arrow__btn right"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(TimeLine);
