import React, { useState } from "react";

import { ReactComponent as TimeLineLogo } from "../../../assets/images/icon_timeline.svg";
import { ReactComponent as ResearchLogo } from "../../../assets/images/icon_bars.svg";
import { ReactComponent as JudgeLogo } from "../../../assets/images/icon_people.svg";
import { ReactComponent as SearchIcon } from "../../../assets/images/search_btn.svg";

const ModeSelector = ({
  mode = "timeline",
  decade = 1950,
  decades = [],
  onDecadeChange = () => null,
  onChangMode = () => null,
}) => {
  const isActive = (tag) => (tag === mode ? "active" : "");
  const isDecadeActive = (tag) => (tag === Number(decade) ? "active" : "");
  const [isHover, setHover] = useState();
  const [isDecadeHover, setDecadeHover] = useState();

  const changeMode = (e, mode) => {
    if (e.currentTarget.classList.value.includes("active")) {
      setHover(true);
    } else {
      setHover(false);
    }
    onChangMode(mode);
  };

  const decadeChange = (e, dec) => {
    if (e.currentTarget.classList.value.includes("active")) {
      setDecadeHover(true);
    } else {
      setDecadeHover(false);
    }
    onDecadeChange(dec);
  };

  return (
    <div className="mode_selector_container">
      <div className={`mode_selector ${isHover ? "hover" : ""}`}>
        <div
          className={`mode_selector__item ${isActive("timeline")}`}
          onClick={(e) => changeMode(e, "timeline")}
        >
          <TimeLineLogo />
          <span>Línea del tiempo</span>
        </div>
        <div
          className={`mode_selector__item ${isActive("winners")}`}
          onClick={(e) => changeMode(e, "winners")}
        >
          <SearchIcon />
          <span>Búsqueda de ganadores</span>
        </div>
        <div
          className={`mode_selector__item ${isActive("research")}`}
          onClick={(e) => changeMode(e, "research")}
        >
          <ResearchLogo />
          <span>Temas de investigación</span>
        </div>
        <div
          className={`mode_selector__item ${isActive("judge")}`}
          onClick={(e) => changeMode(e, "judge")}
        >
          <JudgeLogo />
          <span>Jurado</span>
        </div>
      </div>

      {!!Object.keys(decades).length && (
        <div
          className={`mode_selector decades ${isDecadeHover ? "hover" : ""}`}
        >
          {Object.keys(decades).map((el, i) => (
            <div
              key={i}
              className={`mode_selector__item ${isDecadeActive(Number(el))}`}
              onClick={(e) => decadeChange(e, el)}
            >
              <span>{el}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ModeSelector;
